import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

export const BusinessModule = () => {
  return (
    <div className="business_box">
      <Row gutter={16}>
        <Col span={8}>
          <Card title={<div style={{ textAlign: "center" }}> 财务</div>}>
            <Link to={"/business/income"}>收入{">"}</Link>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
