import React, { FC } from "react";
import { Button, Layout, Menu } from "antd";
import "antd/dist/reset.css";
import "./App.css";
import type { MenuProps } from "antd";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { Login } from "./login";
import { BusinessModule } from "./router/business/business";
import { HomeIncomePage } from "./router/business/home-income";
import { HomeIncomePlat } from "./router/business/income-plat";
const { Sider } = Layout;
type MenuItem = Required<MenuProps>["items"][number];
const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<App2 />}>
          <Route path="business" element={<BusinessModule />} />
        </Route>
        <Route path="/business/income" element={<HomeIncomePage />} />
        <Route path="/business/plat" element={<HomeIncomePlat />}>
          <Route path=":id" element={<HomeIncomePlat />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const App2: FC = () => {
  function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[]
  ): MenuItem {
    return { key, children, label } as MenuItem;
  }
  const items: MenuItem[] = [
    getItem(<Link to={"/home/business"}>业务模块</Link>, "1"),
    getItem(<Link to={""}>其他模块</Link>, "2"),
  ];
  return (
    <div style={{ height: "100%" }}>
      <Layout hasSider={true} style={{ minHeight: "100%" }}>
        <Sider theme="light" style={{ padding: "8px" }}>
          <div className="user_information">
            <div>你好，管理员</div>
            <div className="setting_box">
              <Button>设置</Button>
              <Button>退出登录</Button>
            </div>
          </div>
          <Menu theme="light" mode="inline" items={items} />
        </Sider>
        <Layout className="content-right">
          <Outlet />
        </Layout>
      </Layout>
    </div>
  );
};
export default App;
