import { Breadcrumb } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

export const HomeIncomePage = () => {
  const [plat] = useState([1, 2]);

  return (
    <div className="Income-Content">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={"/home"}>主页</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>收入</Breadcrumb.Item>
      </Breadcrumb>
      <div className="plat-Content-box">
        {plat.map((i, index) => (
          <div key={index} className="each-plat">
            <Link style={{ textDecoration: "none" }} to={`/business/plat/${i}`}>
              平台
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
