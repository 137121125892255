import { Button, Form, Input } from "antd";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
export const Login: FC = (props) => {
  const history = useNavigate();
  const handlesubmit = async (values: any) => {
    history("/home");
    // let res = await axios.post("/login", values);
    // if (res.data.code !== 0) {
    //   message.error(res.data.message);
    //   return;
    // } else {
    //   localStorage.jwt = res.data.data.token;
    //   history("/home");
    // }
  };
  return (
    <div id="login" style={{ height: "100%" }}>
      <Form onFinish={handlesubmit} className="login-form">
        <h2 style={{ textAlign: "center" }}>登录</h2>
        <Form.Item name="userName">
          <Input
            prefix={<UserOutlined style={{ fontSize: 13 }} />}
            placeholder="请输入您的账号!"
          />
        </Form.Item>
        <Form.Item name="userPwd">
          <Input
            prefix={<LockOutlined style={{ fontSize: 13 }} />}
            type="password"
            placeholder="请输入您的密码"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
