import { Breadcrumb, Select } from "antd";
import { Link, useParams } from "react-router-dom";

export const HomeIncomePlat = () => {
  const params = useParams();
  console.log(params);
  return (
    <div className="Income-Content">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={"/home"}>主页</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>收入</Breadcrumb.Item>
        <Breadcrumb.Item>平台{params.id}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="plat-Content-box">
        <div className="each-Content-plat-box">
          <Select style={{ width: "100px" }} defaultValue={"1"}>
            <Select.Option value={"1"}>平台1</Select.Option>
          </Select>
          <div className="account-list-box">12222</div>
        </div>
      </div>
    </div>
  );
};
